import { FILTERS } from "../../../../../Constants/url_params";

import { PARAM_KEYS } from "../../../../Form/DatePicker/constants";
import formatForParams from "../../../../Utils/functions/formatForParams";

import useUpdateFilters from "../../../../Utils/hooks/useUpdateFilters";

const useDoUpdateFilters = (dates, guests, cid) => {
  useUpdateFilters(
    formatForParams(PARAM_KEYS.start, dates.start),
    "no-scroll",
    true
  );
  useUpdateFilters(
    formatForParams(PARAM_KEYS.end, dates.end),
    "no-scroll",
    true
  );
  useUpdateFilters(
    `${FILTERS.guests.paramKey}=${guests}`,
    "no-scroll",
    true,
    true
  );
  useUpdateFilters(`${FILTERS.cid.paramKey}=${cid}`, "no-scroll", true, false);
};

export default useDoUpdateFilters;
