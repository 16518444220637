import React, { useState } from "react";

// Constants
import QUERIES from "../../../Constants/Queries";

// Modules
import { Helmet } from "react-helmet";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import useLogGaEvent from "../../../lib/ga/useLogGaEvent";

// Layout
import Error from "../../Utils/Error";

// Sections
import Header from "./Header";
import Images from "./Images";
import About from "./About";
import BookModal from "./BookModal";
import ThingsToNote from "./ThingsToNote";
import OurPlaceOffers from "./OurPlaceOffers";
import HouseRules from "./HouseRules";
import SleepingArrangements from "./SleepingArrangements";
import Neighbourhood from "./Neighbourhood";
import CancellationPolicy from "./CancellationPolicy";
import FloatingTopbar from "./FloatingTopbar";
import QuestionBox from "./QuestionBox";

// Functions
import fixListingName from "../../Utils/functions/fixListingName";

// Hooks
import useMobileDetect from "../../Utils/hooks/useMobileDetect";

// Translation Package
import { useTranslation } from "react-i18next";
import useCampaignCode from "Components/Utils/hooks/useCampaignCode";

const SuiteContent = ({ setDataLoaded }) => {
  // Translation hook
  const { t } = useTranslation();
  const isMobile = useMobileDetect(992);

  // Book modal
  const [isModalShown, showBookModal] = useState(isMobile);
  const handleShowBookModalClick = () => showBookModal(true);

  // Calendar opener
  const [forceDatesShow, setForceDatesShow] = useState(null);
  const handleOnPriceDatesClick = () => {
    if (isMobile === false) {
      window.scrollTo({
        left: 0,
        top: 541,
      });

      setTimeout(() => {
        setForceDatesShow((f) => (f ? f + 1 : 1));
      }, 500);
    } else {
      setForceDatesShow((f) => (f ? f + 1 : 1));
    }
  };

  // Analytics
  useLogGaEvent(`screen_view`, `suite`);

  const { id } = useParams();
  const campaignCode = useCampaignCode();
  // Data
  const [quotation, setQuotation] = useState({
    quotationData: null,
    quotationLoading: false,
    quotationError: false,
  });

  const fetch = useQuery(QUERIES.PROPERTIES.GET_PROPERTIES, {
    onCompleted: () => setDataLoaded(true),
    //TODO: add the CID here to fetch data related to the Campaign
    variables: {
      filter: {
        propertyID: id,
        ...campaignCode,
      },
    },
  });

  const loading = fetch.loading;
  if (loading) return <Loading />;

  if (fetch.error || fetch.data.result.matches.length === 0) {
    return <Error redirect={true} height="80vh" />;
  }

  const intact = fetch.data.result.matches[0];

  const data = {
    ...intact,
    listingName: fixListingName(intact.listingName),
  };

  return (
    <>
      <Helmet>
        <title>
          {data.listingName} - {t("stella_stays")}
        </title>
        <meta
          name="description"
          content={data?.aboutProperty?.substring(0, 150)}
        />
      </Helmet>

      {!isMobile && (
        <FloatingTopbar
          quotation={quotation}
          suite={data}
          handleOnPriceDatesClick={handleOnPriceDatesClick}
        />
      )}

      {/* Sections */}
      <div className="suite">
        <div className="suite__top-sections">
          <Header suite={data} />
          <Images suite={data} />
        </div>
        <div className="container container-max-xl">
          <div className="suite__book-area">
            <div className="book-area__left">
              <About suite={data} />
              <ThingsToNote suite={data} />
              <OurPlaceOffers suite={data} />
            </div>
            <div className="book-area__right" id="book-area-right">
              <BookModal
                suite={data}
                forceDatesShow={forceDatesShow}
                setForceDatesShow={forceDatesShow}
                isModalShown={isModalShown}
                showBookModal={showBookModal}
                handleShowBookModalClick={handleShowBookModalClick}
                handleOnPriceDatesClick={handleOnPriceDatesClick}
                setQuotation={setQuotation}
              />
            </div>
          </div>
          <HouseRules suite={data} />
          <SleepingArrangements suite={data} />
          <Neighbourhood suite={data} />
          <CancellationPolicy
            suite={data}
            quotation={quotation}
            handleOnPriceDatesClick={handleOnPriceDatesClick}
          />
          <QuestionBox suite={data} quotation={quotation} />
        </div>
      </div>
    </>
  );
};

const Loading = () => {
  return (
    <div className="suite--loading skeleton-loader-holder">
      <div className="loading-top-section">
        <div className="container container-max-xl">
          <div className="loading-box loading-box__title skeleton-loader" />
        </div>
        <div className="container-fluid px-0">
          <div className="loading-box loading-box__header skeleton-loader" />
        </div>
      </div>
      <div className="container container-max-xl">
        <div className="loading-bottom-section gap-4">
          <div className="loading-box loading-box__first-section skeleton-loader" />
          <div className="loading-box loading-box__book-modal skeleton-loader" />
        </div>
      </div>
    </div>
  );
};

export default SuiteContent;
