import { useLocation } from "react-router-dom";

import { FILTERS } from "../../Booking/Search/search_constants";

const useFilters = () => {
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);

  const getFilter = (filter, int) => {
    if (filter === undefined) return {};

    if (int === "int") {
      return {
        [filter.paramKey]: parseInt(params.get(filter.paramKey)),
      };
    }

    return {
      [filter.paramKey]: params.get(filter.paramKey),
    };
  };

  // Build up filters
  const filters = {
    // City
    ...getFilter(FILTERS.city),
    ...getFilter(FILTERS.guests, "int"),
    ...getFilter(FILTERS.searchType),
    ...getFilter(FILTERS.flexibility_type),
    ...getFilter(FILTERS.flexibility_months),
    ...getFilter(FILTERS.startDate),
    ...getFilter(FILTERS.endDate),
    ...getFilter(FILTERS.flexibility),
    ...getFilter(FILTERS.buildings),
    ...getFilter(FILTERS.cid),
  };

  return filters;
};

export default useFilters;
