import { useState } from "react";

import { useMutation } from "@apollo/client";
import { usePaymentContext } from "Components/Booking/Payment/index";
import RadioBox from "Components/Form/RadioBox";
import QUERIES from "Constants/Queries";
import { PAYMENT_CONTEXT, PAYMENT_METHOD } from "Constants/globals";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import BookingAgreementConfirmation from "../../Components/TermsAgreement";
import { validateForm as validateContactForm } from "../ContactReview/validateForm";
import InquirySuccessful from "./InquirySuccessful";
import mobileAppCallback from "Components/Booking/Payment/Forms/functions/mobileAppCallback";
import { paymentErrorCodes } from "../errorCodes";
import { BsExclamationCircleFill } from "react-icons/bs";

// Translation Package
import { useTranslation } from "react-i18next";
import useCampaignCode from "Components/Utils/hooks/useCampaignCode";

const PaymentInquiry = () => {
  // Translation hook
  const { t } = useTranslation();
  const { context, propertyData } = usePaymentContext();

  const { id: propertyID, startDate, endDate, numberOfGuests } = useParams();
  const campaignCode = useCampaignCode();
  const [submissionError, setSubmissionError] = useState(null);
  const [isPaymentInProgress, setIsPaymentInProgress] = useState(false);

  const [isComplete, setIsComplete] = useState(false);
  const [confirmCode, setConfirmCode] = useState(null);

  const methods = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  const [stellaStaysCreateCryptoReservation] = useMutation(
    QUERIES.STELLA_STAYS_CREATE_RESERVATION
  );

  const onSubmission = methods.handleSubmit(async (values) => {
    await methods?.trigger();

    const { isFormValid, ...userProfile } = validateContactForm();
    if (!isFormValid || !methods?.formState?.isValid) {
      return;
    }
    setSubmissionError(null);
    setIsPaymentInProgress(true);
    const mutationVariables = {
      payload: {
        propertyID,
        startDate,
        endDate,
        guests: parseInt(numberOfGuests),
        userProfile,
        reasonForStay: values.reasonForStay,
        ...campaignCode,
      },
    };
    try {
      const response = await stellaStaysCreateCryptoReservation({
        variables: mutationVariables,
      });
      const reservation = response?.data?.result;
      setConfirmCode(reservation?.confirmationCode);

      if (context === PAYMENT_CONTEXT.MOBILE_APP) {
        const message = {
          reservation_id: reservation.reservationID,
          confirmationCode: reservation?.confirmationCode,
          method: PAYMENT_METHOD.INQUIRY,
          status: "success",
        };

        mobileAppCallback(message);
      }

      setIsComplete(true);
    } catch (err) {
      setSubmissionError(
        paymentErrorCodes[err.message]
          ? t(paymentErrorCodes[err.message])
          : t(paymentErrorCodes.DEFAULT)
      );
      setIsPaymentInProgress(false);
    }
  });

  const setReasonForStay = (reason) => {
    methods.setValue("reasonForStay", reason);
    methods?.trigger();
  };

  return (
    <div className="confirm-and-pay__form">
      {/* Form */}
      <FormProvider {...methods}>
        <form onSubmit={onSubmission}>
          <div
            className="container booking-confirmation p-0"
            id="confirmation-page"
          >
            <div className="form-title mb-0">
              <h5 className="form-title__title">{t("reason_for_stay")}</h5>
            </div>
            <div className="row confirmation-content">
              <div className="col-12">
                <div className="row my-2">
                  <div className="col-12 col-md-6 d-flex flex-row gap-2">
                    <div
                      className="form-group floating-label mb-1"
                      style={{
                        boxShadow: "0 0 0 1px rgb(0 0 0 / 10%)",
                        padding: "10px",
                        borderRadius: "6px",
                        width: "100%",
                      }}
                      onClick={() => {
                        setReasonForStay("leisure");
                      }}
                    >
                      <div className="floating-label__input-area">
                        <RadioBox
                          register={methods.register("reasonForStay", {
                            required: true,
                          })}
                          name={"reasonForStay"}
                          label={t("leisure")}
                          value={"leisure"}
                          selected={methods.getValues("reasonForStay")}
                          error={methods?.errors["reasonForStay"]}
                        />
                      </div>
                    </div>

                    <div
                      className="form-group floating-label mb-1"
                      style={{
                        boxShadow: "0 0 0 1px rgb(0 0 0 / 10%)",
                        padding: "10px",
                        borderRadius: "6px",
                        width: "100%",
                      }}
                      onClick={() => {
                        setReasonForStay("corporate");
                      }}
                    >
                      <div className="floating-label__input-area">
                        <RadioBox
                          register={methods.register("reasonForStay", {
                            required: true,
                          })}
                          name={"reasonForStay"}
                          label={t("corporate")}
                          value={"corporate"}
                          selected={methods.getValues("reasonForStay")}
                        />
                      </div>
                    </div>
                  </div>

                  {methods.errors["reasonForStay"] && (
                    <div
                      className="form-group floating-label mb-0 mt-0"
                      role="button"
                    >
                      <div
                        className="error-text d-flex align-items-center"
                        style={{ lineHeight: "14px" }}
                      >
                        <span className="error-text__icon">
                          <BsExclamationCircleFill className="icon" />
                        </span>{" "}
                        {t("please_select_reason_for_stay")}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {submissionError !== null && (
              <div className="alert alert-warning" role="alert">
                {submissionError}
              </div>
            )}
          </div>

          {/* Button */}
          <div className="row">
            <div className="col-12 mb-4">
              <BookingAgreementConfirmation
                isPaymentInProgress={isPaymentInProgress}
                disabled={!methods?.formState?.isValid}
                onClick={null}
                buttonTitle={t("send_inquiry")}
              />
            </div>
          </div>
        </form>
      </FormProvider>

      {isComplete && (
        <InquirySuccessful
          propertyData={propertyData}
          startDate={startDate}
          endDate={endDate}
          reservationID={confirmCode}
        />
      )}
    </div>
  );
};

export default PaymentInquiry;
