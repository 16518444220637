import dayjs from "dayjs";

export const SEARCH_TYPES = {
  calendar: "calendar",
  flexible: "flexible",
};

export const FLEXIBLE_TYPES = {
  weekend: {
    value: "weekend",
    label: "weekend",
  },
  week: {
    value: "week",
    label: "week",
  },
  month: {
    value: "month",
    label: "month",
  },
};

export const FILTERS = {
  city: {
    paramKey: "city",
    defaultValue: "Dubai",
    cookie: "c",
  },
  startDate: {
    paramKey: "start_date",
    defaultValue: null,
    cookie: "sD",
  },
  endDate: {
    paramKey: "end_date",
    defaultValue: null,
    cookie: "eD",
  },
  flexibility: {
    paramKey: "flexibility",
    defaultValue: "0",
    cookie: "f",
  },
  guests: {
    paramKey: "guests",
    apiKey: "guests",
    defaultValue: 1,
    cookie: "g",
  },
  flexibility_type: {
    paramKey: "flexibility_type",
    defaultValue: FLEXIBLE_TYPES.weekend.value,
    cookie: "fT",
  },
  flexibility_months: {
    paramKey: "flexibility_months",
    defaultValue: `${dayjs().format("YYYY-MM")}`,
    cookie: "fM",
  },
  searchType: {
    paramKey: "search_type",
    defaultValue: SEARCH_TYPES.calendar,
    cookie: "sT",
  },
  searchTime: {
    paramKey: "search_time",
    cookie: "t",
    defaultValue: `${dayjs().format()}`,
  },
  buildings: {
    paramKey: "buildings",
    defaultValue: "",
    cookie: "b",
  },
  cid: {
    paramKey: "cid",
    defaultValue: null,
    cookie: "cid",
  },
};

export const DATE_FORMAT = {
  paramFormat: "YYYY-MM-DD",
  apiFormat: "YYYY-MM-DD",
};
