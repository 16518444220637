import React, { useState, useEffect } from "react";

// Packages
import { Helmet } from "react-helmet";
import dayjs from "dayjs";
// Data
import useData from "./hooks/useData";

// Components
import Listings from "./Listings";
import Filters from "./Filters";
import Topbar from "../../Layout/Topbar";
import Footer from "../../Layout/Footer";
import useMobileDetect from "../../Utils/hooks/useMobileDetect";
import useFilters from "./hooks/useFilters";
import logGaEvent from "../../../lib/ga/logGaEvent";

import { DEFAULT_MARKER_STATES } from "./Map/map_constants";

import { FILTERS } from "../../../Constants/url_params";
// Translation Package
import { useTranslation } from "react-i18next";
import Map from "./Map";

import "./index.scss";

const Search = () => {
  // Translation hook
  const { t } = useTranslation();

  const isMobile = useMobileDetect(768);
  const isMobileForMap = useMobileDetect(1200);
  const { data, loading, error, campaignQuery } = useData();
  const filters = useFilters();
  const city = filters[FILTERS.city.paramKey];
  const metaTitle = `${t("website_tab_title_search")} ${t("in")} ${t(
    city?.toLowerCase()
  )} - ${t("stella_stays")}`;

  // Map
  const [map, showMap] = useState(false);
  const toggleMap = (bool) => {
    const boolType = typeof bool;
    if (boolType === "boolean") showMap(bool);
    else showMap(!map);
  };

  // Markers
  const [markerStates, setMarkerStates] = useState(
    DEFAULT_MARKER_STATES.LISTING
  );

  // GA event
  useEffect(() => {
    showMap(isMobileForMap ? false : true);

    const filterValues = {
      city: filters[FILTERS.city.paramKey].toLocaleLowerCase(), // city
      guests: filters[FILTERS.guests.paramKey], // number of guests

      search_type: filters[FILTERS.searchType.paramKey], // [calendar,flexible]

      ...(filters[FILTERS.searchType.paramKey] === "calendar" && {
        checkin_date: filters[FILTERS.startDate.paramKey], // YYYY-MM-DD
        checkout_date: filters[FILTERS.endDate.paramKey], // YYYY-MM-DD
        nights: dayjs(filters[FILTERS.endDate.paramKey]).diff(
          dayjs(filters[FILTERS.startDate.paramKey]),
          "days"
        ), // number of nights
        lead_time: dayjs(filters[FILTERS.startDate.paramKey]).diff(
          dayjs(),
          "days"
        ), // checkin_date - today_date
      }),
      ...(filters[FILTERS.searchType.paramKey] === "flexible" && {
        flexible_length: filters[FILTERS.flexibility_type.paramKey], // weekend, week, month
        flexible_months: filters[FILTERS.flexibility_months.paramKey]
          .split(",")
          .map((month) =>
            dayjs(month, "YYYY-MM").format("MMMM").toLocaleLowerCase()
          )
          .join(", "), // [august, september, ....]
      }),
    };
    if (loading || error) {
      logGaEvent(`search`, filterValues);
      return;
    }

    const availableProperties = data.filter((i) => !i.isAlternative);
    const alternativeProperties = data.filter((i) => i.isAlternative);

    const resultValues = {
      ...filterValues,
      available_properties: availableProperties.length, // number of availables properties shown
      alternative_properties: alternativeProperties.length, // number of alternatives shown shown
      available_properties_list: availableProperties
        .map((i) => i.internalName)
        .join(", "), // (list of properties shown in the available section [DXB-JVC-LT307, DXB-JVC-LT310, ...]
      alternative_properties_list: alternativeProperties
        .map((i) => i.internalName)
        .join(", "), // (list of properties shown in the alternative section [DXB-JVC-LT307, DXB-JVC-LT310, ...]
    };

    logGaEvent(`search_results`, resultValues);
  }, [filters, data, loading, error, isMobile]);

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={t("website_tab_description_main")} />
      </Helmet>
      <Topbar fixed={isMobile === false && true} hideFindAStella />

      <Filters zIndex={11} />
      <div className="container-fluid search_page">
        <div className="row">
          <div className="col-12 listing-area__holder">
            <div className="listing-area__container">
              <Listings
                data={data}
                loading={loading}
                error={error}
                toggleMap={toggleMap}
                map={map}
                isMobile={isMobile}
                markerStates={markerStates}
                setMarkerStates={setMarkerStates}
                campaignQuery={campaignQuery}
              />
              <Map
                data={data}
                city={city}
                loading={loading}
                error={error}
                map={map}
                toggleMap={toggleMap}
                markerStates={markerStates}
                setMarkerStates={setMarkerStates}
                isMobile={isMobile}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer containerFluid={true} />
    </>
  );
};

export default Search;
