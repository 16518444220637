import {gql} from "@apollo/client";
import { PRICE_QUOTATION_FIELDS } from "./Fragments.js"

const QUERIES = {
    USER: {
        VERIFY_TOKEN: gql`
            query stellaStaysVerifyUserToken($token: String!) {
                result: stellaStaysVerifyUserToken(token: $token) {
                    email
                    mobile
                    displayName
                }
            }
        `,
        SET_LOGIN: gql`
            mutation stellaStaysSetUserSignIn($firebaseToken: String!) {
                result: stellaStaysSetUserSignIn(firebaseToken: $firebaseToken) {
                    email
                    mobile
                    displayName
                    jwtToken
                }
            }
        `,
        SET_SIGN_UP: gql`
            mutation stellaStaysSetUserSignUp(
                $payload: StellaStaysUserProfilePayload!
            ) {
                result: stellaStaysSetUserSignUp(payload: $payload) {
                    email
                    mobile
                    displayName
                    jwtToken
                }
            }
        `,
        GET_DETAILS: gql`
            query stellaStaysUserDetails {
                result: stellaStaysUserDetails {
                    streetAddress1
                    streetAddress2
                    city
                    country
                    state
                    zip
                }
            }
        `,
        UPDATE_DETAILS: gql`
            mutation stellaStaysSetUserDetails(
                $payload: StellaStaysUserDetailsPayload!
            ) {
                stellaStaysSetUserDetails(payload: $payload)
            }
        `,
        GET_PROFILE: gql`
            query stellaStaysUserProfile {
                result: stellaStaysUserProfile {
                    firstName
                    lastName
                    email
                    mobile
                    birthdate
                }
            }
        `,
        UPDATE_PROFILE: gql`
            mutation stellaStaysSetUserProfile(
                $payload: StellaStaysUserProfilePayload!
            ) {
                stellaStaysSetUserProfile(payload: $payload)
            }
        `,
        SIGN_IN_WITH_PROVIDER: gql`
            mutation stellaStaysSetUserSignInWithProvider(
                $payload: StellaStaysUserProviderProfilePayload!
            ) {
                result: stellaStaysSetUserSignInWithProvider(payload: $payload) {
                    email
                    displayName
                    jwtToken
                }
            }
        `,
        PREFERRED_LANGUAGE: gql`
            query stellaStaysUserPreferredLanguage {
                result: stellaStaysUserPreferredLanguage {
                    id
                    language
                    direction
                }
            }
        `,
        SET_PREFERRED_LANGUAGE: gql`
            mutation stellaStaysSetUserPreferredLanguage($language: String!) {
                stellaStaysSetUserPreferredLanguage(language: $language)
            }
        `,
    },

    RESERVATIONS: {
        GET_RESERVATIONS: gql`
            query stellaStaysReservations($reservationID: ID) {
                result: stellaStaysReservations(reservationID: $reservationID) {
                    reservation {
                        id
                        confirmationCode
                        isPaid
                        checkIn
                        checkInUnformatted
                        checkInTime
                        checkInInstructions
                        checkOut
                        checkOutUnformatted
                        checkOutTime
                        checkOutInstructions
                        guests
                        guestCheckOutTime
                        isUpcoming
                        isOngoing
                        isPast
                        securityDepositReminder
                        documentsStatus
                        paymentMethod
                        approvedDocuments
                        submittedDocuments
                        documentsStatus
                        channel
                        quotation {
                            startDate
                            endDate
                            nights
                            avgPrice
                            cleaningPrice
                            totalPriceExcludingCleaning
                            totalPrice
                            totalVAT
                            totalPriceIncludingVAT
                            isRangeAvailable
                            violatesLOS
                            minLOS
                            dtcmFees
                            currency
                            creditCardFees
                            sdDueDate
                            securityDepositAmount
                            isFutureSD
                            isSecurityDepositEligible
                            waiverAmount
                            campaignDiscount{
                                percentage
                                discountedValue
                            }
                        }
                    }
                    property {
                        id
                        listingName
                        urlFriendlyID
                        mainImageUrl
                        internalName
                        bedrooms
                        bedrooms
                        avgPrice
                        basePrice
                        baths
                        standardGuests
                        maxGuests
                        checkInHour
                        checkOutHour
                        sleepingArrangements {
                            bedroomMapping
                            bedType
                            bedCount
                        }
                        location {
                            address
                            lat
                            lng
                        }
                        images {
                            id
                            url
                            title
                            description
                        }
                        description
                        houseRules
                        cancellationPolicy
                        city
                    }
                }
            }
        `,
        CREATE_RESERVATION: gql`
            mutation stellaStaysCreateReservation(
                $payload: stellaStaysCreateReservationInput!
            ) {
                result: stellaStaysCreateReservation(payload: $payload) {
                    reservationID
                    paymentLink
                }
            }
        `,
        GET_GUEST_DETAILS: gql`
            query stellaStaysGuestDetails($reservationID: ID!) {
                result: stellaStaysGuestDetails(reservationID: $reservationID) {
                    firstName
                    lastName
                    guestType
                    docOwnerType
                    docs {
                        url
                        name
                        docType
                    }
                }
            }
        `,
        UPDATE_GUEST_DETAILS: gql`
            mutation stellaStaysSetGuestDetails(
                $reservationID: ID!
                $payload: [StellaStaysGuestDetailsPayload!]!
            ) {
                stellaStaysSetGuestDetails(
                    reservationID: $reservationID
                    payload: $payload
                )
            }
        `,
        UPLOAD_MEDIA: gql`
            mutation stellaStaysUploadMedia($file: Upload!) {
                result: stellaStaysUploadMedia(file: $file) {
                    url
                    name
                }
            }
        `,
    },

    PROPERTIES: {
        GET_PROPERTIES: gql`
            query stellaStaysProperties($filter: StellaStaysPropertiesFilterInput!) {
                result: stellaStaysProperties(filter: $filter) {
                    isAlternative
                    matches {
                        id
                        staysAiPropertyID
                        internalName
                        listingName
                        urlFriendlyID
                        mainImageUrl
                        basePrice
                        description
                        bedrooms
                        baths
                        standardGuests
                        maxGuests
                        checkInHour
                        checkOutHour
                        nights
                        avgPrice
                        totalPrice
                        quotationStartDate
                        quotationEndDate
                        quotationMinLOS
                        quotationViolatesLOS
                        displayCurrency
                        location {
                            address
                            lat
                            lng
                        }
                        city
                        houseRules
                        cancellationPolicy
                        thingsToNote {
                            templateID
                            templateName
                            title
                            icon
                            content
                        }
                        aboutProperty
                        specialAnnouncement
                        unitOffers {
                            amenityID
                            title
                            icon
                        }
                        buildingAmenities {
                            amenityID
                            title
                            icon
                        }
                        sleepingArrangements {
                            bedroomMapping
                            bedType
                            bedCount
                        }
                        images {
                            id
                            url
                            title
                            description
                        }
                        features {
                            id
                            title
                            url
                        }
                        propertyShowCaseAmenities {
                            title
                            url
                        }
                        buildingShowCaseAmenities {
                            title
                            url
                        }
                    }
                }
            }
        `,
        PROPERTY_FAQS: gql`
            query stellaStaysFAQs($propertyID: ID!) {
                result: stellaStaysFAQs(propertyID: $propertyID) {
                    id
                    question
                    answer
                }
            }
        `,
        FEATURED_PROPERTIES: gql`
            query stellaStaysProperties($filter: StellaStaysPropertiesFilterInput!) {
                result: stellaStaysProperties(filter: $filter) {
                    matches {
                        id
                        staysAiPropertyID
                        internalName
                        listingName
                        urlFriendlyID
                        mainImageUrl
                        basePrice
                        displayCurrency
                        maxGuests
                        city
                    }
                }
            }
        `,
        FEATURED_PROPERTY_SHOWCASE_AMENITIES: gql`
            query stellaStaysFeaturedPropertyShowCaseAmenities {
                result: stellaStaysFeaturedPropertyShowCaseAmenities {
                    id
                    title
                    url
                }
            }
        `,
        PRICE_QUOTATION: gql`
            query stellaStaysPriceQuotation($payload: StellaStaysQutationInput!) {
                result: stellaStaysPriceQuotation(payload: $payload) {
                    ...PriceQuotationFields,
                }
            }
            ${PRICE_QUOTATION_FIELDS}
        `,
        GET_RESERVATION_BY_CODE: gql`
            query getReservationByCode($code: String!,$paymentMethod: QuotationPaymentMethod!) {
                result: getReservationByCode(code: $code,paymentMethod: $paymentMethod) {
                    id,
                    name,
                    surName,
                    email,
                    phone,
                    address,
                    checkIn,
                    checkOut,
                    propertyID,
                    nights,
                    currency,
                    discount,
                    quotation {
                        ...PriceQuotationFields
                    }
                }
            }
            ${PRICE_QUOTATION_FIELDS}
        `,
        SEARCH_PROPERTIES: gql`
            query stellaStaysPropertySearch(
                $filter: StellaStaysPropertySearchFilterInput!
            ) {
                result: stellaStaysPropertySearch(filter: $filter) {
                    id
                    staysAiPropertyID
                    internalName
                    listingName
                    urlFriendlyID
                    mainImageUrl
                    basePrice
                    location {
                        address
                        lat
                        lng
                    }
                    securityDepositAmount
                    bedrooms
                    baths
                    standardGuests
                    maxGuests
                    checkInHour
                    checkOutHour
                    images {
                        id
                        url
                        title
                        description
                    }
                    nights
                    avgPrice
                    totalPrice
                    quotationStartDate
                    quotationEndDate
                    quotationMinLOS
                    quotationViolatesLOS
                    displayCurrency
                    city
                    isAlternative
                }
            }
        `,
        SEARCH_PROPERTIES_WITHOUT_DATES: gql`
            query stellaStaysProperties($filter: StellaStaysPropertiesFilterInput!) {
                result: stellaStaysProperties(filter: $filter) {
                    isAlternative
                    matches {
                        id
                        staysAiPropertyID
                        internalName
                        listingName
                        urlFriendlyID
                        mainImageUrl
                        basePrice
                        location {
                            address
                            lat
                            lng
                        }
                        bedrooms
                        baths
                        standardGuests
                        maxGuests
                        checkInHour
                        checkOutHour
                        sleepingArrangements {
                            bedroomMapping
                            bedType
                            bedCount
                        }
                        images {
                            id
                            url
                            title
                            description
                        }
                        nights
                        avgPrice
                        totalPrice
                        quotationStartDate
                        quotationEndDate
                        quotationMinLOS
                        quotationViolatesLOS
                        displayCurrency
                    }
                }
            }
        `,
        GET_UNAVAILABLE_DAYS: gql`
            query stellaStaysPropertyUnavailableDays($propertyID: ID!) {
                result: stellaStaysPropertyUnavailableDays(propertyID: $propertyID) {
                    startDate
                    endDate
                    canCheckOutOnStartDate
                }
            }
        `,
        GET_LOS_RANGE: gql`
            query stellaStaysPropertyLOSRange($propertyID: ID!) {
                result: stellaStaysPropertyLOSRange(propertyID: $propertyID) {
                    day
                    los
                }
            }
        `,
    },

    COM_ENUMS: gql`
        query stellaStaysComEnums {
            result: stellaStaysComEnums {
                cities
            }
        }
    `,
    TESTIMONIALS: gql`
        query stellaStaysTestimonials {
            result: stellaStaysTestimonials {
                id
                name
                comment
                url
            }
        }
    `,
    HELP_CENTER: gql`
        query stellaStaysHelpCenterContent {
            result: stellaStaysHelpCenterContent {
                id
                sectionTitle
                sectionID
                question
                answer
            }
        }
    `,
    SETTINGS: gql`
        query stellaStaysSettings {
            result: stellaStaysSettings {
                termsConditions
                privacyPolicy
            }
        }
    `,
    FEATURES: gql`
        query stellaStaysFeatures {
            result: stellaStaysFeatures {
                id
                title
                url
            }
        }
    `,
    BASE_PRICE_RANGE: gql`
        query stellaStaysBasePriceRange {
            result: stellaStaysBasePriceRange {
                minBasePrice
                maxBasePrice
            }
        }
    `,
    SUBMIT_LEASE_TO_STELLA: gql`
        mutation stellaStaysSubmitLeaseToStella(
            $payload: StellaStaysContactMessagePayload!
        ) {
            stellaStaysSubmitLeaseToStella(payload: $payload)
        }
    `,
    SUBMIT_CONTACT_US: gql`
        mutation stellaStaysSubmitContactUs(
            $payload: StellaStaysContactMessagePayload!
        ) {
            stellaStaysSubmitContactUs(payload: $payload)
        }
    `,
    SUBMIT_CORPORATE_FORM: gql`
        mutation stellaStaysSubmitCorporateContact(
            $payload: StellaStaysCorporateContactMessagePayload!
        ) {
            stellaStaysSubmitCorporateContact(payload: $payload)
        }
    `,
    SUBSCRIBE_TO_NEWSLETTER: gql`
        mutation stellaStaysSubscribeToNewsLetter($email: String!) {
            stellaStaysSubscribeToNewsLetter(email: $email)
        }
    `,
    RESERVATION_CONTRACT_DETAILS: gql`
        query stellaStaysReservationContract($contractCode: String!) {
            result: stellaStaysReservationContract(contractCode: $contractCode) {
                bookingDate
                guestName
                guestEmail
                propertyAddress
                city
                rentIncludesDTCM
                rentIncludesVAT
                currency
                rent
                securityDeposit
                totalRent
                checkIn
                checkOut
                checkInTime
                checkOutTime
                propertyNumber
                numberOfGuests
                maxGuests
                houseRules
                cancellationPolicy
                isElectronicSignature
                isSecurityDepositEligible
                extension {
                    originalCheckOut
                    newCheckOut
                    extensionAmount
                    extensionAmountCurrency
                }
            }
        }
    `,
    STELLA_STAYS_CREATE_RESERVATION: gql`
        mutation stellaStaysCreateReservation(
            $payload: stellaStaysCreateReservationInput!
        ) {
            result: stellaStaysCreateReservation(payload: $payload) {
                reservationID
                redirectUrl
                confirmationCode
        }
    }
    `,
    STELLA_STAYS_SETUP_STRIPE_PAYMENT_INTENT: gql`
        mutation stellaStaysSetupStripePaymentIntent(
            $propertyID: ID!
            $startDate: String!
            $endDate: String!
            $reservationCode: String
            $campaignCode: String
        ) {
            result: stellaStaysSetupStripePaymentIntent(
                propertyID: $propertyID
                startDate: $startDate
                endDate: $endDate
                reservationCode: $reservationCode
                campaignCode: $campaignCode
            ) {
                intentReferenceKey
                clientSecret
                publishableKey
            }
        }
    `,
    STELLA_STAYS_FETCH_CRYPTO_EXCHANGE_RATE: gql`
        mutation stellaStaysFetchCryptoExchangeRate(
            $coin: String!
            $network: String!
            $propertyID: ID!
            $startDate: String!
            $endDate: String!
            $reservationCode: String
        ) {
            result: stellaStaysFetchCryptoExchangeRate(
                coin: $coin
                network: $network
                propertyID: $propertyID
                startDate: $startDate
                endDate: $endDate
                reservationCode: $reservationCode
            ) {
                amount
                lookupID
                securityDepositAmount
            }
        }
    `,
    STELLA_STAYS_UPLOAD_BANK_PROOF: gql`
        mutation stellaStaysUploadBankProof($payload: uploadBankProofInput!) {
            uploadBankProof(payload: $payload)
        }
    `,
    SIGN_RESERVATION_CONTRACT: gql`
        mutation stellaStaysSignReservationContract(
            $payload: StellaStaysReservationContractSignaturePayload!
        ) {
            result: stellaStaysSignReservationContract(payload: $payload)
        }
    `,
    SAUDI_INVOICE_ENTITY: gql`
        query saudiInvoiceEntity($entityType: String!, $entityUUID: String!) {
            result: saudiInvoiceEntity(
                entityType: $entityType
                entityUUID: $entityUUID
            ) {
                sellerVATNumber
                invoiceNumber
                guestName
                issueDate
                localDateTime
                totalPriceWithoutVAT
                totalVAT
                creditCardFees
                totalAmountDue
                invoiceItems {
                    item
                    unitPrice
                    quantity
                    unitPriceIncludingVAT
                }
                checkIn
                checkOut
            }
        }
    `,
    FETCH_SUPPORTED_LOCALES: gql`
        query stellaStaysSupportedLanguages {
            result: stellaStaysSupportedLanguages {
                id
                language
                direction
                label
            }
        }
    `,
    FETCH_PAGE_LOCALIZATION: gql`
        query stellaStaysLocalizations($language: String!, $page: String) {
            result: stellaStaysLocalizations(language: $language, page: $page) {
                lookupKey
                localization
            }
        }
    `,
    LOS_DISCOUNT: gql`
    query LOSDiscountToolTip($propertyID: ID!) {
        result: LOSDiscountToolTip(propertyID: $propertyID) {
            # minNights
            # maxNights
            # percentage
            label
            tierDescription
        }
    }
    `,
    GET_INVOICE_DETAILS: gql`
    query getInvoicePaymentDetails($invoiceNumber: String!) {
        invoiceDetails: getInvoicePaymentDetails(invoiceNumber: $invoiceNumber) {
            intentReferenceKey
            publishableKey
            clientSecret
            totalAmount
            type
            startDate
            endDate
            channel
            guests
            currency
            description
            propertyInternalName
            propertyListingName
            confirmationCode
            guestFirstName
            guestLastName
            serviceDueDate
            isPaid
            securityDepositAmount
            securityDepositDueDate
            isSecurityDepositHeld
            isSecurityDepositPaid
            propertyAddress
            mainImageUrl
        }
    }
    `,
    TOTALPAY_SESSION_URL: gql`
        query stellaStaysTotalPayPaymentRedirection($invoiceNumber: String!) {
            result: stellaStaysTotalPayPaymentRedirection(invoiceNumber: $invoiceNumber)
        }
    `,
    ONLINE_CHECK_IN:{
        GET_RESERVATION_BY_CODE: gql`
            query stellaStaysOnlineCheckIn($code: String!, $language: String) {
                result: stellaStaysOnlineCheckIn(code: $code, language:$language) {
                    reservation {
                        firstName
                        lastName
                        email
                        mobile
                        standardCheckInHour
                        arrivalTime
                        specialRequests
                        propertyAddress
                        propertyListingName
                        propertyMaxGuests
                        propertyImageUrl
                        confirmationCode
                        channel
                        numberOfGuests
                        checkIn
                        checkOut
                        countryCode
                        city
                        country
                    }
                    payment{
                        grandTotal
                        reservationTotal
                        securityDepositTotal
                        reservationInvoiceDescription
                        currency
                    }
                    isDocumentSubmissionRequired
                    documents{
                        firstName
                        lastName
                        isGuest
                        docs{
                            name
                            url
                            docType
                        }
                        rejectionRemarksTranslationKey
                    }
                }
            }
        `,
        SUBMIT_UPLOADED_GUESTS_DOCUMENTS: gql`
            mutation stellaStaysSetOnlineCheckInDocuments(
                $code: String!
                $guests: [StellaStaysOnlineCheckInDocumentPayload!]!
                $visitors: [StellaStaysOnlineCheckInDocumentPayload!]
            ) {
                result:stellaStaysSetOnlineCheckInDocuments(
                    code: $code
                    guests: $guests
                    visitors: $visitors
                )
            }

        `,
        SETUP_PAYMENT_INTENT: gql`
            mutation stellaStaysSetupOnlineCheckInPaymentIntent($code: String!) {
                result:stellaStaysSetupOnlineCheckInPaymentIntent(code: $code) {
                    intentReferenceKey
                    publishableKey
                    clientSecret
                }
            }
        `,
        CHECK_PAYMENT_STATUS: gql`
            mutation stellaStaysCheckOnlineCheckInPaymentStatus($code: String!){
                result:stellaStaysCheckOnlineCheckInPaymentStatus(code: $code){
                    isSuccessful
                    failureMessage
                }
            }
        `,
        SUBMIT_PERSONAL_INFO: gql`
            mutation stellaStaysSetOnlineCheckInPersonalInfo($code:String!, $payload:StellaStaysOnlineCheckInPersonalInfoPayload!){
                result:stellaStaysSetOnlineCheckInPersonalInfo(code: $code, payload:$payload)
            }
        `,

        SUBMIT_ONLINE_CHECK_IN: gql`
            mutation stellaStaysSubmitOnlineCheckIn($code: String!){
                result: stellaStaysSubmitOnlineCheckIn(code: $code)
            }
        `
    },
    CAMPAIGN: gql`
        query stellaStaysCampaignPromotionalText($code: String!, $language:String){
            result: stellaStaysCampaignPromotionalText(code:$code, language:$language)
        }
    `
};

export default QUERIES;
