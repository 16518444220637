import React, { useEffect, useState } from "react";

// Constants
import { FILTERS } from "../../../../Constants/url_params";
import { DATE_FORMAT } from "../../../../Constants/url_params";

// Modules
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";

// Lib
import logGaEvent from "../../../../lib/ga/logGaEvent";

// Hooks
import useFilters from "../../../Utils/hooks/useFilters";
import useMobileDetect from "../../../Utils/hooks/useMobileDetect";
import useButtonLabel from "../../../Utils/hooks/useButtonLabel";
import useDatesData from "./hooks/useDatesData";
import useDoUpdateFilters from "./hooks/useDoUpdateFilters";
import useQuotationData from "./hooks/useQuotationData";

// Functions
import getPrice from "./functions/getPrice";
import getOffset from "./functions/getOffset";
import goBookingPage from "./functions/goBookingPage";
import composeJsxClass from "../../../Utils/functions/composeJsxClass";

// Components
import SwipeableCard from "../../../Layout/Cards/SwipeableCard";
import QuotationError from "./QuotationError";
import QuotationSummary from "./QuotationSummary";
import PrimaryButton from "../../../Buttons/Primary";
import DateInput from "../../../Form/DatePicker";
import GuestsInput from "../../../Form/GuestInput";
import { CiImportant } from "../../../../CustomIcons";
import ConditionalWrapper from "../../../Utils/ConditionalWrapper";
import Error from "../../../Utils/Error";
import HouseRules from "../HouseRules";

import ContactBoxSmall from "../ContactBoxSmall";

// Translation Package
import { useTranslation, Trans } from "react-i18next";

import Loading from "Components/Utils/Loading";

const Spinner = () => (
  <div className="spinner">
    <Loading />
  </div>
);

const mainCssClass = "suite__book-modal";
const BookModal = ({
  suite,
  forceDatesShow,
  handleOnPriceDatesClick,
  showBookModal,
  isModalShown,
  handleShowBookModalClick,
  setQuotation,
}) => {
  // Translation hook
  const { t } = useTranslation();

  const history = useHistory();
  const isMobile = useMobileDetect(992);

  const { id } = useParams();
  const filters = useFilters();

  // Filters
  const [dates, setDates] = useState({
    start: filters[FILTERS.startDate.paramKey] || null,
    end: filters[FILTERS.endDate.paramKey] || null,
  });
  const updateDates = (dates) => {
    if (dates.start && dates.end) {
      logGaEvent(`listing_date`, {
        checkin_date: moment(dates.start).format(DATE_FORMAT.paramFormat),
        checkout_date: moment(dates.end).format(DATE_FORMAT.paramFormat),
      });
    }

    setDates({
      start: dates.start
        ? moment(dates.start).format(DATE_FORMAT.paramFormat)
        : null,
      end: dates.end ? moment(dates.end).format(DATE_FORMAT.paramFormat) : null,
    });
  };
  const [guests, updateGuests] = useState(
    filters[FILTERS.guests.paramKey] || 1
  );
  useEffect(() => {
    logGaEvent(`listing_guests`, {
      guests: guests,
    });
  }, [guests]);

  // Data
  const { daysData, daysLoading, daysError } = useDatesData(id);
  const quotation = useQuotationData(dates, id);
  const { quotationData, quotationLoading, quotationError } = quotation;

  useEffect(() => {
    setQuotation(quotationData);
  }, [quotationData, setQuotation]);

  useDoUpdateFilters(dates, guests, filters[FILTERS.cid.paramKey]);

  const handleOnContinueClick = () => {
    logGaEvent(`check_availability`, suite.internalName);
    goBookingPage(history, suite, dates, guests, filters[FILTERS.cid.paramKey]);
  };

  // House rules
  const [mobileHouseRules, showMobileHouseRules] = useState(false);
  const handleHouseRulesClick = () => {
    if (isMobile) {
      showMobileHouseRules(!mobileHouseRules);
      return;
    }
    const houseRules = document.getElementById("house-rules");
    const offsetTop = getOffset(houseRules).top;
    window.scrollTo({
      top: offsetTop,
      left: 0,
      behavior: "smooth",
    });
  };

  const price = getPrice(suite, quotationData);

  const bookModalWrapperStyle = composeJsxClass(mainCssClass, {
    loading: quotationLoading || daysLoading,
  });
  const bookModalStyle = composeJsxClass("book-modal", {
    mobile: isMobile,
  });

  useEffect(() => {
    if (!forceDatesShow) return;
    setTimeout(() => showBookModal(true), 500);
  }, [forceDatesShow, showBookModal]);

  const isDatesSelected = dates.start && dates.end;

  const datesLabel = useButtonLabel("calendar", dates.start, dates.end);

  return (
    <div className={bookModalWrapperStyle}>
      {/* Check availability floating */}
      {isMobile && (
        <div className="book-modal__check-availability">
          <div className="check-availability">
            <div className="container px-0 d-flex align-items-center">
              <div className="check-availability__price">
                {!isDatesSelected && (
                  <span className="price__from">{t(price.from)}</span>
                )}
                <div className="price__holder">
                  <span className="price__data">{price.price}</span>
                  <span className="price__night">/ {t("night")}</span>
                </div>

                {isDatesSelected && (
                  <button
                    className="price__dates-button"
                    onClick={handleOnPriceDatesClick}
                  >
                    {datesLabel}
                  </button>
                )}
              </div>
              <PrimaryButton
                className="check-availability__button"
                onClick={handleShowBookModalClick}
              >
                {/* TODO: i18n */}
                {!isDatesSelected ? t("check_availability") : t("continue")}
              </PrimaryButton>
            </div>
          </div>
        </div>
      )}

      {/* Modal content */}
      <ConditionalWrapper
        condition={isMobile}
        wrapper={(children) => (
          <SwipeableCard
            state={isModalShown}
            toggle={() => showBookModal(!isModalShown)}
            shadowBackground={true}
            isPortal={isMobile}
            disableScrolling={true}
            height={isDatesSelected ? 85 : 450}
            heightType={isDatesSelected ? "vh" : "px"}
            closeIcon={true}
            className={mainCssClass}
            topClassName="book-modal__swipeable-card-top"
            hasContainer={true}
            mobileWidth={992}
          >
            <div
              className="container px-0"
              style={{ height: "100%", position: "relative" }}
            >
              {daysError ? <ErrorBox /> : children}
            </div>
          </SwipeableCard>
        )}
      >
        <div className={bookModalStyle} id="book-modal">
          <div className="book-modal__section book-modal__header">
            {!isDatesSelected && (
              <span className="price__from">{t(price.from)}</span>
            )}
            <div className="price__holder">
              <span className="price__data">{price.price}</span>
              <span className="price__night">/ {t("night")}</span>
            </div>
          </div>

          {/* Date picker and guests */}
          <div className="book-modal__section book-modal__form">
            <div className="date-range position-relative">
              <DateInput
                updateDates={updateDates}
                initialDateValues={dates}
                header={isMobile ? t("select_dates") : null}
                minimumNights={suite.nights || 3}
                autoClose={true}
                forceClose={false}
                loading={daysLoading}
                suite={suite}
                sortedLosDates={daysData.sortedLosDates}
                sortedUnavailableRanges={daysData.sortedUnavailableRanges}
                availableDatesConfig={daysData.availableDatesConfig}
                compact={true}
                autoScrollIntoView={true}
                forceShow={forceDatesShow}
              />
              {daysData.workerIsWorking && (
                <div
                  className="position-absolute start-0 top-0 end-0 bottom-0 d-flex justify-content-start px-5 pt-2"
                  style={{ background: "rgba(255,255,255,0.5)", zIndex: 1 }}
                  id="calendar-calc-loader"
                >
                  <Spinner />
                </div>
              )}
            </div>

            <div className="guests">
              <GuestsInput
                update={updateGuests}
                initialValue={guests}
                maxGuests={suite?.maxGuests || 10}
              />
            </div>
          </div>

          {/* Continue button */}
          <div
            className="book-modal__section book-modal__continue"
            hidden={isMobile}
          >
            <PrimaryButton
              onClick={handleOnContinueClick}
              type="button"
              className="continue-button"
              disabled={quotationLoading || quotationError || !isDatesSelected}
              label={t("continue")}
            />
            <div className="continue-tooltip" hidden={isDatesSelected}>
              {t("datepicker_text")}
            </div>
            <div className="continue-info">{t("continue_charge_label")}</div>
          </div>

          {/* Quotation summary */}
          {!quotationError && (
            <div className="book-modal__section book-modal__quotation-summary">
              <QuotationSummary
                data={quotationData}
                loading={quotationLoading}
              />
            </div>
          )}

          <div className="book-modal__section book-modal__quotation-error">
            <QuotationError data={!quotationLoading && quotationData} />
          </div>

          <ContactBoxSmall suite={suite} />

          {/* Deposit info */}
          {quotationData?.isSecurityDepositEligible ? (
            <div
              className={`book-modal__section book-modal__deposit-info ${
                isDatesSelected ? `book-modal__deposit-info--shown` : ""
              }`}
            >
              <div className="deposit-info__check-icon">
                <CiImportant className="icon" />
              </div>
              <div className="deposit-info__description ">
                {/* 
                  Using Trans component to handle complex jsx
                  Trans component splits jsx into array and interpolates translation based on index
                  these indexes can be used in translation to put translation in correct place
                  More at https://react.i18next.com/latest/trans-component
                */}
                <Trans i18nKey="security_deposit_notice">
                  {/* Add empty string to act as placeholder for <0><0> */}{" "}
                  <button
                    className="house-rules-anchor"
                    onClick={handleHouseRulesClick}
                  >
                    {/* Add empty string to act as placeholder for <1><1> */}{" "}
                  </button>
                  {/* Add empty string to act as placeholder for <2><2> */}{" "}
                </Trans>
                {mobileHouseRules && <HouseRules suite={suite} />}
              </div>
            </div>
          ) : null}
        </div>

        {/* Continue button */}
        <div
          className="book-modal__section book-modal__continue continue-mobile d-flex flex-column"
          hidden={!isMobile}
        >
          <PrimaryButton
            onClick={handleOnContinueClick}
            type="button"
            disabled={quotationLoading || quotationError || !isDatesSelected}
            label={t("continue")}
          />
          <div className="continue-info">{t("continue_charge_label")}</div>
        </div>
      </ConditionalWrapper>
    </div>
  );
};

const ErrorBox = () => {
  const bookModalStyle = composeJsxClass(mainCssClass, {
    error: true,
  });
  return (
    <div className={bookModalStyle}>
      <Error />
    </div>
  );
};

export default BookModal;
