import { useQuery } from "@apollo/client";
import QUERIES from "../../../../Constants/Queries";
import dayjs from "dayjs";

import useFilters from "../../../Utils/hooks/useFilters";
import {
  FILTERS,
  DATE_FORMAT,
  SEARCH_TYPES,
  FLEXIBLE_MONTHS_FORMAT,
} from "../../../../Constants/url_params";
import { useHistory } from "react-router-dom";
import useCampaignCode from "Components/Utils/hooks/useCampaignCode";
import { useTranslation } from "react-i18next";

const getFlexibleMonths = (months) => {
  if (!months) return;

  return months
    .split(",")
    .map((month) =>
      dayjs(month, FLEXIBLE_MONTHS_FORMAT).format("MMM").toLocaleLowerCase()
    );
};

const useData = () => {
  const filters = useFilters();
  const history = useHistory();
  const campaignCode = useCampaignCode();
  const searchParams = new URLSearchParams(window.location.search);
  const { i18n } = useTranslation();
  const buildings =
    searchParams
      .get("buildings")
      ?.split(",")
      .filter((s) => s !== "") ?? [];

  /** TODO do a proper handle for this in useFilters() hook for default cases
      Stella stays Dubai link on google search is broken
      adding a manual redirect as hotfix */
  if (!filters.city) {
    history.push(
      `/search?city=Dubai&guests=1&search_type=calendar&buildings=${buildings.join(
        ","
      )}`
    );
  }

  const searchType =
    filters[FILTERS.searchType.paramKey] || SEARCH_TYPES.calendar;

  // Dates
  const startDateFilter = filters[FILTERS.startDate.paramKey];
  const endDateFilter = filters[FILTERS.endDate.paramKey];

  // Check if dates are valid
  const isStartDateValid = dayjs(startDateFilter).isValid();
  const isEndDateValid = dayjs(endDateFilter).isValid();
  const areDatesValid = isStartDateValid && isEndDateValid;

  // Format dates
  const dateFormat = DATE_FORMAT.apiFormat;
  const formattedStartDate = dayjs(startDateFilter).format(dateFormat);
  const formattedEndDate = dayjs(endDateFilter).format(dateFormat);
  const dates = areDatesValid && {
    dates: {
      start: formattedStartDate,
      end: formattedEndDate,
    },
  };

  // Flexible
  const flexibleType = filters[FILTERS.flexibility_type.paramKey];
  const flexibleMonths = getFlexibleMonths(
    filters[FILTERS.flexibility_months.paramKey]
  );
  const isFlexibleValid = flexibleType && flexibleMonths;
  const flexible = isFlexibleValid && {
    flexible: {
      type: flexibleType,
      months: flexibleMonths,
    },
  };

  const guests = filters[FILTERS.guests.paramKey] || null;

  const filter = {
    city: filters[FILTERS.city.paramKey],
    ...(guests && { guests: parseInt(guests) }),

    // ...(searchType === SEARCH_TYPES.calendar && start && end && dates),
    ...(searchType === SEARCH_TYPES.calendar && dates),
    ...(searchType === SEARCH_TYPES.flexible && flexible),
    buildingIDs: buildings,
    ...campaignCode,
  };

  const campaignQuery = useQuery(QUERIES.CAMPAIGN, {
    variables: {
      code: campaignCode?.campaignCode,
      language: i18n.languages[0],
    },
    skip: !campaignCode?.campaignCode || !i18n.language[0],
  });

  const hasDatesOrFlexible = dates || flexible;

  const query = hasDatesOrFlexible
    ? QUERIES.PROPERTIES.SEARCH_PROPERTIES
    : QUERIES.PROPERTIES.SEARCH_PROPERTIES_WITHOUT_DATES;

  // Fetch data
  const fetch = useQuery(query, {
    variables: {
      filter,
    },
    fetchPolicy: "no-cache",
  });

  const data = hasDatesOrFlexible
    ? fetch.data?.result
    : fetch.data?.result?.matches;

  const loading = fetch.loading;
  const error = fetch.error;

  return {
    data: !loading && data,
    loading: loading,
    error: error,
    campaignQuery,
  };
};

export default useData;
