import React, { useCallback, useEffect, useRef, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import QUERIES from "../../../../Constants/Queries";
import ShimmerLoading from "../../../Utils/Shimmer";
import BookingAgreementConfirmation from "../Components/TermsAgreement";
import { paymentErrorCodes } from "./errorCodes";
import { validateForm as validateContactForm } from "./ContactReview/validateForm";
import { usePaymentContext } from "Components/Booking/Payment/index";
import PriceNoticeBox from "../Components/PriceNoticeBox";
import toMoney from "Components/Utils/functions/toMoney";
import { URL_PARAMS } from "Constants/globals";

// Translation Package
import { useTranslation, Trans } from "react-i18next";

import dayjs from "dayjs";
import useCampaignCode from "Components/Utils/hooks/useCampaignCode";
require("dayjs/locale/ar");
let isToday = require("dayjs/plugin/isToday");
dayjs.extend(isToday);

let stripeInstance = null;
let stripeElements = null;

const StripePaymentCard = () => {
  // Translation hook
  const { t, i18n } = useTranslation();
  const {
    context,
    invCode,
    quotationData,
    showSDSeparate,
    isSecurityDepositEligible,
  } = usePaymentContext();
  const campaignCode = useCampaignCode();
  const { id: propertyID, startDate, endDate, numberOfGuests } = useParams();
  const [stellaStaysSetupStripePaymentIntent] = useMutation(
    QUERIES.STELLA_STAYS_SETUP_STRIPE_PAYMENT_INTENT
  );
  const [stellaStaysCreateStripeOnSessionReservation] = useMutation(
    QUERIES.STELLA_STAYS_CREATE_RESERVATION
  );
  const [isPaymentInProgress, setIsPaymentInProgress] = useState(false);
  const [isStripeLoading, setIsStripeLoading] = useState(true);
  const [paymentError, setPaymentError] = useState(null);
  const paymentIntentReferenceKey = useRef(null);

  /**
   * Initialization
   */
  const initializeStripe = useCallback(
    async (propertyID) => {
      try {
        const response = await stellaStaysSetupStripePaymentIntent({
          variables: {
            propertyID,
            startDate,
            endDate,
            reservationCode: invCode,
            ...campaignCode,
          },
        });
        paymentIntentReferenceKey.current =
          response.data.result.intentReferenceKey;
        stripeInstance = await loadStripe(response.data.result.publishableKey);
        const appearance = {
          theme: "stripe",
          variables: {
            fontFamily:
              i18n?.dir() === "rtl"
                ? "Almarai, Helvetica Neue, sans-serif"
                : "Gilroy, Almarai, sans-serif",
          },
          labels: "floating",
          rules: {
            ".Input": {
              borderRadius: "6px",
              boxShadow: "0 0 0 1px rgba(0, 0, 0, 0.08)",
              marginBottom: "12px",
            },
            ".Input:focus": {
              borderColor: "transparent",
              borderRadius: "6px",
              boxShadow: "0 0 0 2px rgba(117, 150, 159, 0.9)",
            },
            ".Label": {
              color: "#576f76",
              fontWeight: "500",
              fontSize: "14px",
            },
            ".TermsText": {
              fontWeight: "400",
              color: "#000000",
              fontSize: "14px",
            },
          },
        };
        stripeElements = stripeInstance.elements({
          locale: i18n?.languages?.[0] ?? "en",
          fonts: [
            {
              family: "Almarai",
              src: "url(https://fonts.gstatic.com/s/almarai/v12/tssoApxBaigK_hnnS-agtnqWo4z1oXk.woff2)",
            },
          ],
          loader: "always",
          appearance,
          clientSecret: response.data.result.clientSecret,
        });
        const paymentElement = stripeElements.create("payment");
        paymentElement.mount("#payment-element");
      } catch (err) {
        setPaymentError(
          paymentErrorCodes[err.message]
            ? t(paymentErrorCodes[err.message])
            : t(paymentErrorCodes.DEFAULT)
        );
      } finally {
        setIsStripeLoading(false);
      }
    },
    [endDate, i18n?.languages, startDate, stellaStaysSetupStripePaymentIntent]
  );

  /**
   * On submit
   */
  const onFormSubmission = async (e) => {
    e.preventDefault();
    if (stripeElements === null || stripeInstance === null) {
      return;
    }
    const { isFormValid, ...userProfile } = validateContactForm();
    if (!isFormValid) {
      return;
    }
    setPaymentError(null);
    setIsPaymentInProgress(true);
    let redirectUrl;
    //create reservation
    try {
      const reservationPayload = {
        payload: {
          propertyID,
          startDate,
          endDate,
          guests: parseInt(numberOfGuests),
          userProfile: {
            ...userProfile,
            birthdate: userProfile.birthdate ?? null,
          },
          intentReferenceKey: paymentIntentReferenceKey.current,
          reservationCode: invCode,
          ...campaignCode,
        },
      };
      const response = await stellaStaysCreateStripeOnSessionReservation({
        variables: reservationPayload,
      });

      // Safely Add param to url
      let urlToWorkOn = new URL(response?.data?.result?.redirectUrl);
      urlToWorkOn.searchParams.set(URL_PARAMS.CONTEXT, context);
      urlToWorkOn.searchParams.set(
        URL_PARAMS.CONFIRMATION_CODE,
        response?.data?.result?.confirmationCode
      );
      if (campaignCode?.campaignCode) {
        urlToWorkOn.searchParams.set("cid", campaignCode?.campaignCode);
      }
      redirectUrl = urlToWorkOn.toString();
    } catch (reservationErr) {
      setIsPaymentInProgress(false);
      setPaymentError(
        paymentErrorCodes[reservationErr.message]
          ? t(paymentErrorCodes[reservationErr.message])
          : t(paymentErrorCodes.DEFAULT)
      );
      return;
    }
    const { error } = await stripeInstance.confirmPayment({
      elements: stripeElements,
      confirmParams: {
        return_url: redirectUrl,
      },
    });
    setIsPaymentInProgress(false);
    setPaymentError(error.message);
  };
  /**
   * Initialize payment element
   */
  useEffect(() => {
    initializeStripe(propertyID);
  }, [initializeStripe, propertyID]);

  // Calc Total
  let total = quotationData.totalPriceIncludingVAT || quotationData.totalPrice;
  if (!showSDSeparate && isSecurityDepositEligible) {
    total = total + quotationData?.securityDepositAmount;
  }

  return (
    <>
      <form
        id="payment-form"
        style={{ padding: "10px", paddingTop: "15px" }}
        onSubmit={onFormSubmission}
      >
        <div
          className="container booking-confirmation p-0"
          id="confirmation-page"
        >
          <div className="row confirmation-content">
            <div className="col-12">
              <div className="shadow-card journey-area border">
                {paymentError !== null && (
                  <div className="col-12 d-flex flex-column gap-1 mb-4">
                    <div className="alert alert-warning" role="alert">
                      {t(paymentError)}
                    </div>
                  </div>
                )}
                <div className="col-12 d-flex flex-column gap-1 mb-4">
                  <PriceNoticeBox
                    title={t("total")}
                    price={toMoney(total, quotationData.currency)}
                  />
                </div>

                {/* Step 1 */}
                <div className="step step_one" id="crypto-network-selector">
                  <div className="step__indicator">
                    <div className="indicator__text">1</div>
                    {showSDSeparate && isSecurityDepositEligible && (
                      <div className="indicator__line"></div>
                    )}
                  </div>
                  <div className="step__content w-100">
                    <h6 className="step__title mb-4">
                      {t("enter_credit_card_details")}
                    </h6>

                    {isStripeLoading && <ShimmerLoading />}

                    <div id="payment-element">
                      {/* Stripe.js injects the Payment Element here */}
                    </div>
                    <br />
                  </div>
                </div>

                {/* Property booked */}
                {showSDSeparate && isSecurityDepositEligible && (
                  <div className="step step_one" id="crypto-network-selector">
                    <div className="step__indicator">
                      <div className="indicator__text">2</div>
                    </div>
                    <div className="step__content w-100">
                      <h6 className="step__title mb-2">
                        {t("refundable_security_deposit_title")}
                      </h6>

                      <PriceNoticeBox
                        title={t("security_deposit_payment_title")}
                        price={toMoney(
                          quotationData.securityDepositAmount,
                          quotationData.currency
                        )}
                      />
                      <p
                        className="step__description mt-2 stella_h4 fw-normal"
                        style={{ fontSize: "14px" }}
                      >
                        <Trans
                          i18nKey="hold_on_card"
                          values={{
                            date:
                              showSDSeparate && isSecurityDepositEligible
                                ? `${t("due_on")} ${dayjs(
                                    quotationData?.sdDueDate
                                  )
                                    .locale(i18n.languages[0])
                                    .format("DD MMM YYYY")}`
                                : t("today"),
                          }}
                        >
                          {" "}
                          <strong> </strong>{" "}
                        </Trans>
                      </p>
                      <br />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="complete-booking-button mt-3 mb-4">
          <BookingAgreementConfirmation
            isPaymentInProgress={isPaymentInProgress}
            onClick={null}
          />
        </div>
        <div id="payment-message" className="hidden" />
      </form>
    </>
  );
};

export default React.memo(StripePaymentCard);
