import React from "react";
import dayjs from "dayjs";

import toMoney from "../../Utils/functions/toMoney";
import fixListingName from "../../Utils/functions/fixListingName";
import StickyBox from "react-sticky-box";

import { BsArrowRight } from "react-icons/bs";
import Tooltip from "Components/Utils/Tooltip";
import { BiInfoCircle } from "react-icons/bi";
import calcSetShowSDSeparate from "Components/Booking/Payment/Forms/functions/shouldShowSDSeparate.js";
import { TWO_DAYS_IN_HOURS } from "Constants/globals";

// Translation Package
import { useTranslation } from "react-i18next";
require("dayjs/locale/ar");

const dateFormat = "DD MMMM YYYY";
const ListingDetails = (props) => {
  // Translation hook
  const { t, i18n } = useTranslation();

  const { data, isMobile } = props;

  const property = data.property;
  const reservation = data.reservation;
  const quotation = reservation.quotation;
  const isSecurityDepositEligible = quotation.isSecurityDepositEligible;

  const listingName = fixListingName(property.listingName);
  const currency = quotation.currency;
  const nightsCount = quotation.nights;
  const nights = `${nightsCount} ${t(`night${nightsCount > 1 ? "s" : ""}`)}`;
  const checkInIsAfterTwoDay =
    dayjs(quotation?.startDate).diff(dayjs(), "hours") > TWO_DAYS_IN_HOURS;
  const shouldShowSDSeparate = calcSetShowSDSeparate(
    quotation,
    reservation?.paymentMethod
  );
  const fees = [
    {
      label: `${currency} ${quotation.avgPrice}`,
      calcLabel: `${nights}`,
      fee: quotation.totalPriceExcludingCleaning,
    },
    ...(quotation?.campaignDiscount
      ? [
          {
            label: t("campaign_promotion_discount", {
              percentage: quotation?.campaignDiscount?.percentage,
            }),
            fee: -quotation?.campaignDiscount?.discountedValue,
            textColor: "#49B338",
          },
        ]
      : []),
    {
      label: t("cleaning_fee"),
      fee: quotation.cleaningPrice,
    },
    {
      label: t("taxes_and_fees"),
      fee:
        (quotation.totalVAT || 0) +
        (quotation.dtcmFees || 0) +
        quotation.creditCardFees +
        (!isSecurityDepositEligible ? quotation.waiverAmount : 0),
    },
  ];

  return (
    <div className={`col-${isMobile ? "12" : "5"}`}>
      <StickyBox offsetTop={90} offsetBottom={20} className="sticky-box">
        <div className="sticky-card" id="listing-details">
          <div className="shadow-card px-0 large-shadow listing-details">
            <div className="listing-details__image">
              <img src={property.mainImageUrl} alt={listingName} />
            </div>

            <div className="listing-details__title listing-content">
              <h6 className="title__label">{t("success_card_title")}</h6>
              <div className="title__confirmation">
                <span className="data-label">{t("confirmation_code")}</span>
                <span className="data-content code">
                  {reservation.confirmationCode}
                </span>
              </div>
            </div>

            <div className="listing-details__property listing-content">
              <div className="content-item">
                <span className="data-label">
                  {t("success_card_subtitle_1")}
                </span>
                <span className="data-content">{listingName}</span>
              </div>

              <div className="content-item">
                <span className="data-label">
                  {t("success_card_subtitle_2")}
                </span>
                <span className="data-content address">
                  {property.location.address}
                </span>
              </div>

              <div className="content-item">
                <span className="data-label">
                  {t("success_card_subtitle_3")}
                </span>
                <span className="data-content">
                  {reservation.guests}{" "}
                  {reservation.guests > 1 ? t(`guests_counter`) : t("guest")}
                </span>
              </div>

              <div className="content-item">
                <span className="data-label">{nights}</span>
                <span className="data-content d-flex flex-row gap-2 align-items-center">
                  {dayjs(reservation.checkInUnformatted)
                    .locale(i18n.languages[0])
                    .format(dateFormat)}{" "}
                  <BsArrowRight className="date-arrow" />
                  {dayjs(reservation.checkOutUnformatted)
                    .locale(i18n.languages[0])
                    .format(dateFormat)}
                </span>
              </div>
            </div>

            <div className="listing-details__fees listing-content">
              {fees
                .filter((item) => !item.fee || item.fee !== 0)
                .map((item) => (
                  <div
                    className="content-item"
                    key={item.label}
                    style={{ color: item?.textColor ? item?.textColor : "" }}
                  >
                    <span
                      className="data-label d-flex flex-row gap-2"
                      style={{ color: item?.textColor ? item?.textColor : "" }}
                    >
                      {item.calcLabel && <span>{item.calcLabel} x</span>}
                      <span>{item.label}</span>
                    </span>
                    <span className="data-content">
                      {toMoney(item.fee, currency)}
                    </span>
                  </div>
                ))}

              {!shouldShowSDSeparate && isSecurityDepositEligible && (
                <div className="content-item">
                  <span className="data-label d-flex flex-row gap-2">
                    <Tooltip text={t("refundable_security_deposit_desc")}>
                      <span role={"button"}>
                        {t("refundable_security_deposit_title")}
                      </span>{" "}
                      <BiInfoCircle
                        fill="#576f76"
                        className="icon mx-1"
                        role={"button"}
                      />
                    </Tooltip>
                  </span>
                  <span className="data-content">
                    {toMoney(quotation.securityDepositAmount, currency)}
                  </span>
                </div>
              )}
            </div>

            <div className="listing-details__total-amount">
              <h5>{t("total_amount")}</h5>
              <h5>
                {toMoney(
                  (quotation.totalPriceIncludingVAT || quotation.totalPrice) +
                    (!shouldShowSDSeparate && isSecurityDepositEligible
                      ? quotation.securityDepositAmount
                      : 0),
                  currency
                )}
              </h5>
            </div>
          </div>

          {/* Security Deposit */}
          {shouldShowSDSeparate && isSecurityDepositEligible && (
            <div className="d-flex flex-column my-3">
              <div className="shadow-card px-0 py-0 large-shadow listing-details overflow-visible">
                <div
                  className="listing-details__total-amount d-flex flex-row gap-2 justify-content-between py-5"
                  style={{ background: "transparent" }}
                >
                  <div className="d-flex flex-column">
                    <h6 className="mb-0" role={"button"}>
                      <Tooltip text={t("refundable_security_deposit_desc")}>
                        {t("refundable_security_deposit_title")}{" "}
                        <BiInfoCircle fill="#576f76" className="icon mx-1" />
                      </Tooltip>
                    </h6>

                    {checkInIsAfterTwoDay && (
                      <p className="mb-0 mt-2 stella_featured_para">
                        <span>{`${t("due_on")} ${dayjs(quotation?.sdDueDate)
                          .locale(i18n.languages[0])
                          .format("DD MMM YYYY")}`}</span>
                      </p>
                    )}
                  </div>

                  <h6>{toMoney(quotation.securityDepositAmount, currency)}</h6>
                </div>
              </div>
            </div>
          )}
        </div>
      </StickyBox>
    </div>
  );
};

export default ListingDetails;
