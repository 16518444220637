import React, { useEffect, useState } from "react";
// Translation Package
import { Trans, useTranslation } from "react-i18next";
import { CONTACT_EMAIL, CONTACT_NUMBER } from "../../../../Constants/globals";
import logGaEvent from "../../../../lib/ga/logGaEvent";
import useButtonLabel from "../../../Utils/hooks/useButtonLabel";
import useFilters from "../../../Utils/hooks/useFilters";
import ListingCard from "./ListingCard";
import QuestionBox from "./QuestionBox";
import { default as QuestionBoxFromListing } from "../../Suite/QuestionBox";

const loaders = [0, 1, 2, 3, 4];
const SearchListings = (props) => {
  //Translation hook
  const { t, i18n } = useTranslation();

  const {
    data: intactData,
    error,
    loading,
    toggleMap,
    setMarkerStates,
    markerStates,
    map,
    isMobile,
    campaignQuery,
  } = props;

  const questionBoxPosition = 2;
  const [data, setData] = useState(intactData);
  useEffect(() => setData(intactData), [intactData]);

  const filters = useFilters();

  const listingClass = error
    ? "error"
    : loading
    ? "skeleton-loader-holder"
    : "";

  const buttonLabel = useButtonLabel(
    filters.search_type,
    filters.start_date,
    filters.end_date,
    filters.flexibility_type,
    filters.flexibility_months
  );
  const staysInDateLabel =
    buttonLabel !== t("select_dates") ? `${buttonLabel}` : "";

  const noMainResults =
    data && data.filter((d) => !d.isAlternative).length === 0;

  const alternativeResultsLength =
    data && data.filter((d) => d.isAlternative).length;

  let questionBoxPadding = { right: "0", left: "64px" };
  if (isMobile) {
    questionBoxPadding = { right: "0", left: "0" };
  } else {
    switch (i18n.dir()) {
      case "rtl": {
        questionBoxPadding = { right: "64px", left: "0" };
        break;
      }
      default: {
        questionBoxPadding = { right: "0", left: "64px" };
      }
    }
  }

  return (
    <div className="search_page__listings">
      <div className="listings">
        {!loading && (
          <>
            {/* Main Listings */}
            <div className="row listings-row">
              <div className="col-12 listings-title-holder">
                <div className="listing-count" hidden={noMainResults}>
                  {staysInDateLabel && staysInDateLabel}
                </div>
                <h4 className="listing-title">
                  {t("stays_in")} {t(filters?.city?.toLowerCase())}
                </h4>
              </div>

              {/* promotion Text */}
              {campaignQuery?.data?.result ? (
                <section className="col-12">
                  <div className="campaign-promotion-text">
                    {campaignQuery?.data?.result}
                  </div>
                </section>
              ) : null}

              {data &&
                data
                  .filter((data) => !data.isAlternative)
                  .map((item, index) => (
                    <>
                      {/* display Question box at 3rd */}
                      {index === questionBoxPosition && index >= 2 && (
                        <div
                          className="col-12 col-md-6 listings-col"
                          key={"contactboxInline"}
                        >
                          <QuestionBox city={filters.city} />
                        </div>
                      )}
                      <ListingCard
                        key={item.id}
                        fullList={data}
                        data={item}
                        index={index}
                        markerStates={markerStates}
                        setMarkerStates={setMarkerStates}
                        isMobile={isMobile}
                        map={map}
                      />

                      {/* display Question box at end */}
                      {/* Check if this is the last listing and data length is less than position */}
                      {index === data.length - alternativeResultsLength - 1 &&
                        data.length - alternativeResultsLength <
                          questionBoxPosition + 1 && (
                          <div
                            className="col-12 col-md-6 listings-col"
                            key={"contactboxLast"}
                          >
                            <QuestionBox city={filters.city} />
                          </div>
                        )}
                    </>
                  ))}

              {noMainResults && (
                <div className="col-12">
                  <div className="not-found-any">
                    <h5>{t("no_results")}</h5>
                    <div className="not-found-any__text">
                      {t("no_results_description")}
                    </div>
                    <div
                      style={{
                        paddingLeft: questionBoxPadding.left,
                        paddingRight: questionBoxPadding.right,
                      }}
                    >
                      <QuestionBoxFromListing
                        title={t("title_question_box_no_results")}
                        suite={{ listingName: `${filters.city} properties` }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/* Alternative listings */}
            {alternativeResultsLength > 0 && (
              <div className="row listings-row">
                <div className="col-12 mt-5 alternative-title-holder">
                  <div className="alternative-title-holder__left">
                    <h5 className="listing-title alternative-title">
                      {t("available_for_other_dates")}
                    </h5>
                  </div>
                </div>
                {data &&
                  [...intactData]
                    .filter((data) => data.isAlternative)
                    .splice(0, alternativeResultsLength)
                    .map((item, index) => (
                      <ListingCard
                        key={index}
                        fullList={data}
                        data={item}
                        index={index}
                        alternative={true}
                        markerStates={markerStates}
                        setMarkerStates={setMarkerStates}
                      />
                    ))}
              </div>
            )}
            <div className="row" hidden={noMainResults}>
              <div className="col-12 end">
                <ContactUsBox end />
              </div>
            </div>
          </>
        )}

        {loading && (
          <div className="row">
            {loaders.map((loader, index) => (
              <div className="col-12 col-md-6 col-lg-12 col-xl-6" key={index}>
                <div className={listingClass}>
                  <div className="skeleton-loader"></div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      {/* Map Button */}
      <div className="map-button">
        <button className="map-button__button" onClick={toggleMap}>
          {t("view_map")}
        </button>
      </div>
    </div>
  );
};

const ContactUsBox = (props) => {
  //Translation hook
  const { t } = useTranslation();

  const { small, end } = props;
  const smallClass = small ? "contact_us--small" : "";
  const endClass = end ? "contact_us--end" : "";

  const handleGaEvent = (method) => {
    logGaEvent(`contact_us`, {
      method: method,
    });
  };
  return (
    <div className={`contact_us ${smallClass} ${endClass}`}>
      <Trans
        i18nKey="reach_out_notice"
        values={{ whatsapp: CONTACT_NUMBER.HUMAN, email: CONTACT_EMAIL }}
      >
        Reach out to our team via WhatsApp on{" "}
        <a
          href={`https://wa.me/${CONTACT_NUMBER.EMBED.replace("+", "").replace(
            /\s/g,
            ""
          )}?text=${encodeURI(t(`hi_question`))}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => handleGaEvent("whatsapp")}
        >
          {" "}
        </a>{" "}
        or email us on{" "}
        <a
          href={`mailto:${CONTACT_EMAIL}?subject=Inquiry&body=${encodeURI(
            t(`hi_question`)
          )}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => handleGaEvent("email")}
        >
          {" "}
        </a>{" "}
      </Trans>
    </div>
  );
};

export default SearchListings;
