import React from "react";

// Packages
import dayjs from "dayjs";

// Utils
import fixListingName from "../../../Utils/functions/fixListingName";

// Functions
import toMoney from "./../../../Utils/functions/toMoney";

// Misc
import { BsArrowRight } from "react-icons/bs";
import { BiInfoCircle } from "react-icons/bi";
import Tooltip from "Components/Utils/Tooltip";
import Loading from "Components/Utils/Loading";

// Translation Package
import { useTranslation } from "react-i18next";

// Components
import LOSDiscountTooltip from "Components/Booking/Suite/los-discount-details";

// Router
import { useParams } from "react-router-dom";

require("dayjs/locale/ar");

const SuiteInfo = (props) => {
  // Translation hook
  const { t, i18n } = useTranslation();
  const { id } = useParams();

  const {
    propertyData,
    quotationData,
    quotationDataLoading,
    startDate,
    endDate,
    guests,
    showQuotation,
    showSDSeparate,
    isSecurityDepositEligible,
  } = props;

  // Calc Total
  let total =
    quotationData?.totalPriceIncludingVAT || quotationData?.totalPrice;
  if (!showSDSeparate && isSecurityDepositEligible) {
    total = total + quotationData?.securityDepositAmount;
  }

  return (
    <div className="confirm-and-pay__suite">
      {/* Title and price button */}
      {propertyData && (
        <div className="header gap-2 gap-md-0">
          <div className="header__image">
            <img
              className="image"
              src={propertyData.mainImageUrl}
              alt={propertyData.listingName}
            />
          </div>
          <div className="header__right w-100">
            <div className="header__listing_name pb-1">
              {fixListingName(propertyData.listingName)}
            </div>
            <div className="header__location pb-0">
              {fixListingName(propertyData.location.address)}
            </div>
          </div>
        </div>
      )}

      {/* Quotation Block */}
      <div className="d-flex flex-column position-relative">
        {/* Trip summary */}
        <div className="info">
          <div className="info__title">{t("your_trip")}</div>

          <div className="d-flex flex-row gap-1 gap-md-4 align-items-center justify-content-between">
            <div className="info__guests">
              <div className="info__small-title">{t("guests")}</div>
              <div className="guests info__data">
                {guests} {guests > 1 ? t(`guests_counter`) : t("guest")}
              </div>
            </div>
            <span style={{ color: "rgba(75,81,84,0.2)" }}>|</span>

            <div className="info__dates">
              {/* If Quotation Fetched show nights from quotation */}
              {quotationData && (
                <div className="info__small-title">
                  {quotationData?.nights}{" "}
                  {t(`night${quotationData?.nights > 1 ? "s" : ""}`)}
                </div>
              )}
              {/* Else show fallback  */}
              {!quotationData && (
                <div className="info__small-title">{t("dates")}</div>
              )}
              <div className="dates gap-2">
                <div className="dates__checkin info__data">
                  {dayjs(startDate)
                    .locale(i18n.languages[0])
                    .format("DD MMM YYYY")}
                </div>
                <div className="dates__arrow">
                  <BsArrowRight className="icon" />
                </div>
                <div className="dates__checkout info__data">
                  {dayjs(endDate)
                    .locale(i18n.languages[0])
                    .format("DD MMM YYYY")}
                </div>
              </div>
            </div>
          </div>
        </div>

        {showQuotation && quotationData && (
          <>
            {/* Price summary */}
            <div className="price my-0 py-2">
              <div className="price__title mt-0">{t("price_details")}</div>
              <PriceItem
                label={`${toMoney(
                  quotationData.avgPrice,
                  quotationData.currency
                )}`}
                calcLabel={`${quotationData.nights} ${t(
                  "night" + (quotationData.nights > 1 ? "s" : "")
                )}`}
                price={toMoney(
                  quotationData.totalPriceExcludingCleaning +
                    (quotationData?.losDiscount?.discountedValue ?? 0),
                  quotationData.currency
                )}
              />

              {quotationData?.campaignDiscount ? (
                <div className="price__item">
                  <div
                    className="item__label d-flex flex-row gap-2 align-items-center"
                    style={{ color: "#49B338" }}
                  >
                    <span>
                      {t("campaign_promotion_discount", {
                        percentage: quotationData?.campaignDiscount?.percentage,
                      })}
                    </span>
                  </div>

                  <div className="item__price" style={{ color: "#49B338" }}>
                    {toMoney(
                      -quotationData?.campaignDiscount?.discountedValue,
                      quotationData.currency
                    )}
                  </div>
                </div>
              ) : null}

              {!!quotationData?.losDiscount?.discountedValue && (
                <LOSDiscountTooltip
                  label={
                    <span className="item__price__positive-amount">
                      {quotationData.losDiscount.label}
                    </span>
                  }
                  amount={
                    <div className="item__price__positive-amount">
                      {toMoney(
                        quotationData.losDiscount.discountedValue,
                        quotationData.currency
                      )}
                    </div>
                  }
                  propertyID={id}
                  className="price__item"
                />
              )}
              <PriceItem
                label={t("cleaning_fee")}
                price={toMoney(
                  quotationData.cleaningPrice,
                  quotationData.currency
                )}
              />

              <PriceItem
                label={t("taxes_and_fees")}
                price={toMoney(
                  (quotationData.totalVAT || 0) +
                    (quotationData.dtcmFees || 0) +
                    quotationData.creditCardFees +
                    quotationData.waiverAmount,
                  quotationData.currency
                )}
              />

              {!showSDSeparate && isSecurityDepositEligible && (
                <div className="price__item">
                  <div className="item__label d-flex flex-row gap-2 align-items-center">
                    <Tooltip text={t("refundable_security_deposit_desc")}>
                      <div
                        className="flex flex-row align-items-center"
                        role="button"
                      >
                        <span>{t("refundable_security_deposit_title")}</span>
                        <BiInfoCircle fill="#576f76" className="icon mx-1" />
                      </div>
                    </Tooltip>
                  </div>

                  <div className="item__price">
                    {toMoney(
                      quotationData.securityDepositAmount,
                      quotationData.currency
                    )}
                  </div>
                </div>
              )}
            </div>

            {/* Total */}
            <div className="total mb-0 py-3 border-top">
              <div className="total__label">{t("total_amount")}</div>
              <div className="total__price">
                {toMoney(total, quotationData.currency)}
              </div>
            </div>

            <hr
              className="mt-0 d-sm-none"
              style={{
                borderTop: "5px solid rgba(61, 78, 83, 0.12)",
                opacity: "100%",
              }}
            />
          </>
        )}

        {quotationDataLoading && (
          <div
            className="position-absolute start-0 top-0 end-0 bottom-0 d-flex justify-content-start px-5 pt-2 rounded-3"
            style={{ background: "rgba(255,255,255,0.8)", zIndex: 1 }}
            id="calendar-calc-loader"
          >
            <Loading />
          </div>
        )}
      </div>
    </div>
  );
};

const PriceItem = ({ calcLabel, label, price }) => {
  return (
    <div className="price__item">
      <div className="item__label d-flex flex-row gap-2 align-items-center">
        {calcLabel && <span>{calcLabel} x</span>} <span>{label}</span>
      </div>

      <div className="item__price">{price}</div>
    </div>
  );
};

export default SuiteInfo;
